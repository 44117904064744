import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import Image from "../../elements/Image";

const propTypes = {
  black: PropTypes.bool,
};
const defaultProps = {
  black: true,
};

const Logo = ({ className, black, ...props }) => {
  const classes = classNames("brand", className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            style={{ width: 120 }}
            src={
              black
                ? require("./../../../assets/images/logo.svg")
                : require("./../../../assets/images/logo-white.svg")
            }
            alt="Open"
          />
        </Link>
      </h1>
    </div>
  );
};

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
