import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
// import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {
  const outerClasses = classNames(
    "features-split section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-split-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  // const sectionHeader = {
  //   title: "Why Phetty? Because gifting done right is hard.",
  //   paragraph:
  //     "While the people important to your business are distributed, they still require personal 1-to-1 attention, recognition, and care. With our all-inclusive and fully-branded gifting solution, we enable you to be in full control of a streamlined gifting strategy that empowers your whole team to give personal 1-to-1 gifts without all the heavy lifting or costly investments.",
  // };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12 fw-500">
                  When you need to <strong>go above and beyond</strong>
                </h3>
                <p className="m-0">
                  Doing business means dealing with people, whether that's your
                  clients, prospects, investors, partners, or, most importantly,
                  your employees. More than ever before, these people are
                  looking for caring businesses. We empower you to go above and
                  beyond a simple "thanks" to show genuine care when it matters
                  the most.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner")}
                  src={require("./../../assets/images/illustration-split-01.png")}
                  alt="Features split 01"
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-right"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12 fw-500">
                  <span className="tt-strike">Generic bulk gifting.</span>{" "}
                  <strong>Genuine 1-to-1 gifting.</strong>
                </h3>
                <p className="m-0">
                  Gifts can be sent in bulk. Care cannot. Instead of traditional
                  and very much impersonal bulk gifting, we give each employee
                  the power to show genuine care for those moments that require
                  it the most. Not in bulk, but 1-to-1, as it should be.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner")}
                  src={require("./../../assets/images/illustration-split-02.png")}
                  alt="Features split 02"
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12 fw-500">
                  Distributed gifting <strong>with more control</strong>
                </h3>
                <p className="m-0">
                  Enable your whole team to send personalized 1-to-1 gifts while
                  easily ensuring a fully-branded, unified, and streamlined
                  gifting experience. Continuously maintain full control and
                  oversight of all gifts with company-wide limits, approvals,
                  and policies. Easily override, the default company controls
                  with individual restrictions and limitations for each employee
                  when needed.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner")}
                  src={require("./../../assets/images/illustration-split-03.png")}
                  alt="Features split 03"
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12 fw-500">
                  <strong>Gifts that are wanted,</strong> not wasted
                </h3>
                <p className="m-0">
                  A wasted gift is a wasted opportunity. A wanted gift is
                  memorable, meaningful, and right for the moment. We don't
                  offer thousands of random gifts nor thoughtless gift cards,
                  but instead a carefully curated gift selection from partners
                  who genuinely care for their products. We select products with
                  an eye for outstanding quality and design, and when possible
                  our first choice is always local and sustainable.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner")}
                  src={require("./../../assets/images/illustration-split-04.png")}
                  alt="Features split 04"
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12 fw-500">
                  It's fully branded. <strong>We make it yours.</strong>
                </h3>
                <p className="m-0">
                  It should not be about us. It should be entirely about you. We
                  offer a streamlined solution from our platform to our products
                  that enables a unified brand experience. We go the extra mile
                  to make it yours with fully branded packaging, custom
                  messaging with handwritten cards, and an over-all
                  brand-tailored digital experience.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner")}
                  src={require("./../../assets/images/illustration-split-05.png")}
                  alt="Features split 05"
                />
              </div>
            </div>

            <div className="split-item">
              <div
                className="split-item-content center-content-mobile reveal-from-left"
                data-reveal-container=".split-item"
              >
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8"></div>
                <h3 className="mt-0 mb-12 fw-500">
                  <strong>It's all-inclusive.</strong> Yes, we handle it.
                </h3>
                <p className="m-0">
                  We do all the heavy lifting so that you can focus on running
                  your business. We handle everything from procurement,
                  warehouse, packaging to delivery, customs, and returns, all at
                  a low and fully transparent price.
                </p>
              </div>
              <div
                className={classNames(
                  "split-item-image center-content-mobile reveal-from-bottom",
                  imageFill && "split-item-image-fill"
                )}
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner")}
                  src={require("./../../assets/images/illustration-split-06.png")}
                  alt="Features split 06"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
