import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Image from "../elements/Image";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const HeroSolutions = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <div className="hero-figure reveal-from-bottom solutions-illustration">
              <Image
                className="mb-32"
                data-reveal-delay="600"
                src={require("./../../assets/images/solutions-illustration.png")}
                alt="Solutions cover"
                style={{
                  maxHeight: 340,
                }}
              />
            </div>
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Personal 1-to-1 gifting{" "}
              <span className="text-color-primary">
                <strong>that scales</strong>
              </span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Launch and scale a streamlined gifting strategy that empowers
                your whole team to give personal 1-to-1 gifts without all the
                heavy lifting, administrative headaches, and costly investments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroSolutions.propTypes = propTypes;
HeroSolutions.defaultProps = defaultProps;

export default HeroSolutions;
