import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Accordion from "../../components/elements/Accordion";
import AccordionItem from "../../components/elements/AccordionItem";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const BenefitQA3 = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "benefit-qa-3 section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "benefit-qa-3-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner mb-8")}
                  src={require("./../../assets/images/icons/big-delivery.svg")}
                  alt="platform icon"
                  style={{
                    width: 45,
                  }}
                />
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Benefits
                </div>
                <h3 className="mt-0 mb-12 fw-500">The Delivery</h3>
                <p className="m-0">
                  Delivery is all-inclusive. At a low, flat fee, we deliver both
                  nationally and internationally, handle customs and duties, and
                  take care of returns.
                </p>
              </div>
              <div
                className="split-item-content reveal-from-right"
                data-reveal-container=".split-item"
              >
                <Accordion className="questions">
                  <AccordionItem title="We deliver internationally">
                    The people important to your business are everywhere.
                    Employees working in multiple offices or even remote are
                    becoming a new reality, and this while business is
                    continuously being cross-border with international clients,
                    customers, and partners. We remove the pain of delivering
                    gifts with international delivery to +28 countries for a
                    completely transparent and flat fee.
                  </AccordionItem>
                  <AccordionItem title="We deliver at a flat fee">
                    Our delivery network gives us access to low-cost shipping
                    rates that enable us to offer you a completely transparent,
                    up-front, and flat delivery price - this no matter the gifts
                    you send and their destination.
                  </AccordionItem>
                  <AccordionItem title="We handle customs and duties">
                    Customs and duties are a pain to deal with. We make sure
                    that when you send a gift, you don’t need to worry. All
                    gifts are delivered with customs and duties included, so you
                    avoid any nasty surprises.
                  </AccordionItem>
                  <AccordionItem title="We handle returns">
                    If a person who has received a gift wants to return it, we
                    take care of it without any work from your side. On top of
                    this, with our Gift Links functionality, you can avoid
                    sending a wrong gift in the first place as the recipients
                    can choose a gift themselves from our Gift Store. However,
                    if a return is needed - whatever the reason - we handle it
                    at no cost.
                  </AccordionItem>
                  <AccordionItem title="We keep you updated (Beta)">
                    To connect with your recipient as soon as they have received
                    your gift you want to know when it’s delivered. We keep you
                    updated with live tracking and notifications that enable you
                    to know the exact trace of your delivery and avoid any
                    surprises.
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BenefitQA3.propTypes = propTypes;
BenefitQA3.defaultProps = defaultProps;
export default BenefitQA3;
