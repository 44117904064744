import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

const tileData = [
  {
    title: "In-house gift selection",
    description:
      "Inventory, fulfillment, and service are all in-house to ensure outstanding quality.",
    icon: require("./../../assets/images/icons/in-house-gift-selection.svg"),
  },
  {
    title: "Direct delivery",
    description:
      "Select a gift and send it directly to your office or another address that you specify. ",
    icon: require("./../../assets/images/icons/direct-delivery.svg"),
  },
  {
    title: "Flexible gift links",
    description:
      "Send a gift via a fully branded link wherever you communicate. No address needed",
    icon: require("./../../assets/images/icons/flexible-gift-links.svg"),
  },
  {
    title: "Delivery tracking",
    description: "Stay updated with live delivery tracking and notifications.",
    icon: require("./../../assets/images/icons/delivery-tracking.svg"),
  },
  {
    title: "Custom branding",
    description:
      "Customize the experience with colors, logos, and more to match your brand.",
    icon: require("./../../assets/images/icons/custom-branding.svg"),
  },
  {
    title: "All-inclusive packaging",
    description:
      "Each gift comes in free, on-brand, and responsible inner and outer packaging.",
    icon: require("./../../assets/images/icons/all-inclusive-packaging.svg"),
  },
  {
    title: "Personal gift cards",
    description:
      "Write a message with your gift and we’ll write a handwritten card.",
    icon: require("./../../assets/images/icons/personal-gift-cards.svg"),
  },
  {
    title: "Gift limits and rights",
    description:
      "Automatically stay in control of right and spending for each user as well as company-wide.",
    icon: require("./../../assets/images/icons/gift-limits-and-rights.svg"),
  },
  {
    title: "Enforce policies (Coming)",
    description:
      "Easily and automatically enforce a clear company gift policy on the go.",
    icon: require("./../../assets/images/icons/enforce-policies.svg"),
  },
  {
    title: "Custom approval rules (Coming)",
    description:
      "Enable custom approval rules for both automatic and manual approval",
    icon: require("./../../assets/images/icons/custom-approval-rules.svg"),
  },
  {
    title: "Activity trails",
    description:
      "Be in control with full insight into all gifting activity company-wide.",
    icon: require("./../../assets/images/icons/activity-trails.svg"),
  },
  {
    title: "Analytics and reporting",
    description:
      "Stay on top of all company gifts in real-time with powerful analytics.",
    icon: require("./../../assets/images/icons/analytics-and-reporting.svg"),
  },
];

const SolutionTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "solution-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "solution-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  const sectionHeader = {
    title: "What you get",
  };

  return (
    <section {...props} className={outerClasses} id="all-features">
      <div className="container">
        <div className={innerClasses}>
          <div className="center-content">
            <h6 className="text-color-primary tt-u">Solution</h6>
          </div>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={tilesClasses}>
            {tileData.map((tile) => (
              <div key={tile.title} className="tiles-item reveal-from-bottom">
                <div className="tiles-item-inner center-content-mobile">
                  <div className="features-tiles-item-header">
                    <div className="features-tiles-item-image mb-16">
                      <Image
                        src={tile.icon}
                        alt={tile.title}
                        width={64}
                        height={64}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h6 className="mt-0 mb-8 text-xs">{tile.title}</h6>
                    <p className="m-0 text-xs">{tile.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

SolutionTiles.propTypes = propTypes;
SolutionTiles.defaultProps = defaultProps;

export default SolutionTiles;
