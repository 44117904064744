import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const HeroPricing = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // };

  // const closeModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              A price you can’t beat.{" "}
              <span className="text-color-primary">
                <strong>It’s free</strong>
              </span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Our pricing is transparent and straightforward without any
                hidden mark-up or fees. Our platform is completely free, and you
                only pay for the gifts you send. For enterprises, we also offer
                a few additional add-ons.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup className="mb-16">
                  <Button
                    tag="a"
                    color="dark"
                    wideMobile
                    // eslint-disable-next-line no-undef
                    onClick={() => $crisp.push(["do", "chat:open"])}
                  >
                    Get early access
                  </Button>
                </ButtonGroup>
                <div className="mt-8 text-xs">
                  or{" "}
                  <a
                    href="/solution#all-features"
                    className="center-content"
                    style={{
                      textDecoration: "underline",
                      color: "black",
                    }}
                  >
                    see all features
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

HeroPricing.propTypes = propTypes;
HeroPricing.defaultProps = defaultProps;

export default HeroPricing;
