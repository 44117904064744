import React from "react";
import classNames from "classnames";
import SectionHeader from "./partials/SectionHeader";
import Accordion from "../../components/elements/Accordion";
import AccordionItem from "../../components/elements/AccordionItem";

const PricingQA = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-pricing-qa section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-pricing-qa-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Pricing",
    titleSecond: "questions",
    paragraph:
      "The most common questions that we get. Please contact us if you have a questions that does not get answered here. ",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content section-header"
          />
          <div className="questions-outer">
            <Accordion className="questions">
              <AccordionItem title="Do you offer discounts?" active>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
              <AccordionItem title="Are there any other fees?">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
              <AccordionItem title="When do you charge?">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
              <AccordionItem title="How do I control my spending?">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
              <AccordionItem title="What are the payment options?">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingQA;
