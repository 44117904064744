import React from "react";
import classNames from "classnames";
import SectionHeader from "./partials/SectionHeader";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";

const EnterPriseAddons = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "enterprise-addons section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "enterprise-addons-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Enterprise",
    titleSecond: "add-ons",
    paragraph:
      "On top of our standard platform, we also offer additional add-ons for enterprises. While we want to be as transparent as possible about the price we have a collaborative process to develop and deliver an enterprise solution tailored to your needs, ",
  };

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content section-header"
          />
          <div className="center-content mb-32">
            <ButtonGroup className="mb-16">
              <Button
                tag="a"
                color="dark"
                wideMobile
                // eslint-disable-next-line no-undef
                onClick={() => $crisp.push(["do", "chat:open"])}
              >
                Get early access
              </Button>
            </ButtonGroup>
          </div>

          <div className={tilesClasses}>
            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="pricing-item-content">
                  <div className="pricing-item-header">
                    <div className="pricing-item-price mb-4">
                      <span className="check"></span>
                    </div>
                  </div>
                  <div className="pricing-item-features mb-40">
                    <div className="pricing-item-features-title text-xs mb-24">
                      <strong>Customize your gift selection</strong> with
                      products we handpick together.
                    </div>
                  </div>
                </div>
                <div className="pricing-item-cta mb-8">
                  <strong>From $995 per month</strong>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="pricing-item-content">
                  <div className="pricing-item-header">
                    <div className="pricing-item-price mb-4">
                      <span className="check"></span>
                    </div>
                  </div>
                  <div className="pricing-item-features mb-40">
                    <div className="pricing-item-features-title text-xs mb-24">
                      Have gifts sent in your own completely{" "}
                      <strong>tailored packaging.</strong>
                    </div>
                  </div>
                </div>
                <div className="pricing-item-cta mb-8">
                  <strong>From $495 per month</strong>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="pricing-item-content">
                  <div className="pricing-item-header">
                    <div className="pricing-item-price mb-4">
                      <span className="check"></span>
                    </div>
                  </div>
                  <div className="pricing-item-features mb-40">
                    <div className="pricing-item-features-title text-xs mb-24">
                      Include <strong>custom merchandise</strong> like stickers,
                      cups, and more.
                    </div>
                  </div>
                </div>
                <div className="pricing-item-cta mb-8">
                  <strong>From $195 per month</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EnterPriseAddons;
