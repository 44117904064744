import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
  pricingSlider: PropTypes.bool,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
  pricingSlider: false,
};

class Pricing extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      pricingSlider,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "pricing section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "pricing-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className={tilesClasses}>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                          Platform
                        </span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title text-xs text-color-high mb-24">
                        Completely free with all services included and no hidden
                        fees.
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">+AAA Gifting Partner </li>
                        <li className="is-checked">Unlimited users</li>
                        <li className="is-checked">
                          All&nbsp;
                          <a
                            href="/solution"
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            features and functionality
                          </a>
                        </li>
                        <li className="is-checked">Outstanding support</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8 h4 tt-u">Free</div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                          Gifts
                        </span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title text-xs text-color-high mb-24">
                        Pay as you go for the gifts you send without any hidden
                        mark-up.
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">
                          Handpicked gift selection
                        </li>
                        <li className="is-checked">Responsible packaging</li>
                        <li className="is-checked">Free on-brand packaging</li>
                        <li className="is-checked">
                          Personalized and branded cards
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8 h4">
                    From $25 per gift
                  </div>
                </div>
              </div>
              <div
                className="tiles-item reveal-from-bottom"
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h3">
                          Delivery
                        </span>
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title text-xs text-color-high mb-24">
                        International delivery to 28 countries at a low up-front
                        cost.
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">
                          Fast international delivery
                        </li>
                        <li className="is-checked">
                          Flat, transparent, and low cost
                        </li>
                        <li className="is-checked">Customs and duties</li>
                        <li className="is-checked">
                          Return and exchange handling
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8 h4">
                    $12 per delivery
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
