import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Accordion from "../../components/elements/Accordion";
import AccordionItem from "../../components/elements/AccordionItem";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const BenefitQA1 = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "benefit-qa-1 section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "benefit-qa-1-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner mb-8")}
                  src={require("./../../assets/images/icons/big-platform.svg")}
                  alt="platform icon"
                  style={{
                    width: 45,
                  }}
                />
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Benefits
                </div>
                <h3 className="mt-0 mb-12 fw-500">The Platform</h3>
                <p className="m-0">
                  Full access to all functionality empowers you to easily scale
                  a fully streamlined gifting program across your entire team.
                </p>
              </div>
              <div
                className="split-item-content reveal-from-right"
                data-reveal-container=".split-item"
              >
                <Accordion className="questions">
                  <AccordionItem title="We make personal scale">
                    In an increasingly distributed and remote world, managing a
                    streamlined gift program at scale is hard. Making it
                    personal - beyond just Christmas and Thanksgiving - even
                    more. However, personal is what is being increasingly
                    demanded by the people important to your business. At
                    Phetty, we empower your entire team to easily meet this
                    demand with personal 1-to-1 gifts that catch the crucial
                    moments while you effortlessly maintain full control and
                    oversight.
                  </AccordionItem>
                  <AccordionItem title="We give you full control">
                    We give you full control while making all the unnecessary
                    administration a thing of the past. In the driver’s seat of
                    your gift program, you can now maintain complete oversight
                    with company-wide limits, approvals, and policies. In the
                    background, we automize all the heavy lifting putting an end
                    to wasted time dealing with gift spending, approvals,
                    receipts, packaging, and delivery.
                  </AccordionItem>
                  <AccordionItem title="We make it flow easy">
                    Avoid wasting valuable time rushing stores finding the
                    perfect gift. We got you covered. In three clicks and two
                    minutes max, your team can send a pre-approved gift wherever
                    they need it. Just select a gift directly in our gift store,
                    add a personal note, and send it via a link or directly to
                    an address. We take care of the rest.
                  </AccordionItem>
                  <AccordionItem title="We onboard you in minutes">
                    In the same amount of time that just one of your employees
                    can find and send one generic gift, we can give your entire
                    team the power to continuously send highly memorable gifts
                    without almost any effort. Sign up in minutes and give your
                    employees full access within just a few hours.
                  </AccordionItem>
                  <AccordionItem title="We go above and beyond">
                    In our kind of business, we believe that it is beyond
                    crucial that people can tell that we care just as much as
                    you. Following this philosophy, we do everything we can to
                    always go above and beyond and deliver the best gifts and
                    services for you and the people important to your business.
                    To do this, we have a 100% caring team dedicated to guiding
                    and supporting you whenever you need it via phone, email, or
                    chat.
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BenefitQA1.propTypes = propTypes;
BenefitQA1.defaultProps = defaultProps;
export default BenefitQA1;
