import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ConsentGate, MetomicProvider } from "@metomic/react";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

//import './App.css';
import "./assets/scss/style.scss";
import Tracking from "./Tracking";

const history = createBrowserHistory();

ReactDOM.render(
  <Router history={history}>
    <MetomicProvider projectId="prj:dfec252b-fce4-4cfa-9d10-1619f25b38e5">
      <ConsentGate micropolicy="analytics">
        <Tracking />
      </ConsentGate>
    </MetomicProvider>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
