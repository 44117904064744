import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

// Initialize Google Analytics

const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const Tracking = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_CODE);
  let location = useLocation();

  useEffect(() => {
    const page = location.pathname;
    trackPage(page);
  }, [location]);

  return <div></div>;
};

export default Tracking;
