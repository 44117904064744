/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <Link to="#0">Terms of use</Link>
        </li>
        <li>
          <Link to="#0">Privacy policy</Link>
        </li>
        <li>
          <a href="javascript:window.Metomic('ConsentManager:show')">
            Cookie policy
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
