import React from "react";
// import sections
import HeroPricing from "../components/sections/Hero-Pricing";
import PricingSection from "../components/sections/Pricing";
import EnterPriseAddons from "../components/sections/EnterpriseAddons";
import Cta from "../components/sections/Cta";
import PricingQA from "../components/sections/PricingQA";
// import FeaturesBasic from "../components/sections/FeaturesBasic";
// import FeaturesTiles from "../components/sections/FeaturesTiles";
// import FeaturesSplit from "../components/sections/FeaturesSplit";
// import Cta from "../components/sections/Cta";
// import Testimonial from "../components/sections/Testimonial";

const Pricing = () => {
  return (
    <>
      <HeroPricing />
      <PricingSection />
      <EnterPriseAddons />
      <PricingQA />
      <Cta />
    </>
  );
};

export default Pricing;
