import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    "cta section center-content-mobile reveal-from-bottom mt-32 mb-32",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "cta-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider",
    split && "cta-split"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan center-content">
            <h3 className="m-0 mb-16">
              <span className="fw-400">Less work.</span>{" "}
              <strong>More care</strong>
            </h3>
            <p className="text-xs">
              Reach out to get early access to Phetty today or book a demo with
              us and we’ll be in touch as soon as possible.
            </p>
          </div>
          <ButtonGroup className="">
            <Button
              tag="a"
              color="dark"
              wideMobile
              // eslint-disable-next-line no-undef
              onClick={() => $crisp.push(["do", "chat:open"])}
            >
              Get early access
            </Button>
            <Button
              tag="a"
              color=""
              wideMobile
              href="https://calendly.com/phetty/demo"
            >
              <span className="text-color-primary">Book a demo</span>
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </section>
  );
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
