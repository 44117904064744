import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames(
    "tiles-wrap center-content",
    pushLeft && "push-left"
  );

  const sectionHeader = {
    title: "Why Phetty?",
    titleSecond: "Because gifting done right is hard.",
    paragraph:
      "While the people important to your business are distributed, they still require personal 1-to-1 attention, recognition, and care. With our all-inclusive and fully-branded gifting solution, we enable you to launch and scale a completely streamlined gifting program that empowers your whole team to give personal 1-to-1 gifts without any administrative headaches, heavy lifting, or costly investments.",
  };

  return (
    <section {...props} className={outerClasses} id="why-phetty">
      <div className="container">
        <div className={innerClasses}>
          <Image
            className="mb-32 cover-image"
            src={require("./../../assets/images/feature-tiles-cover.png")}
            alt="Woman working"
          />
          <Image
            className="mb-32 cover-image cover-image--mobile"
            src={require("./../../assets/images/feature-tiles-cover-mobile.png")}
            alt="Woman working"
          />
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className="center-content">
            <h6 className="text-color-primary">HOW IT WORKS</h6>
          </div>

          <div className={tilesClasses}>
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require("./../../assets/images/how-we-do-it-01.svg")}
                      alt="Features tile icon 01"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h6 className="mt-0 mb-8">Define and set limits</h6>
                  <p className="m-0 text-xs">
                    Maintain full control and oversight of your gifting program
                    with custom limits, approvals, and policies.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="200"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require("./../../assets/images/how-we-do-it-02.svg")}
                      alt="Features tile icon 02"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h6 className="mt-0 mb-8">Give your team access</h6>
                  <p className="m-0 text-xs">
                    Give your team the power to give when it matters the most to
                    the people most important to your business
                  </p>
                </div>
              </div>
            </div>

            <div
              className="tiles-item reveal-from-bottom"
              data-reveal-delay="400"
            >
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="mb-16">
                    <Image
                      src={require("./../../assets/images/how-we-do-it-03.svg")}
                      alt="Features tile icon 03"
                    />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h6 className="mt-0 mb-8">Send gifts that matter</h6>
                  <p className="m-0 text-xs">
                    Select and send memorable gifts in a just a few clicks and
                    we take care of all the rest. Yes, everything.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="mt-16 text-xs reveal-from-bottom"
              data-reveal-delay="400"
            >
              <a
                href="/solution"
                className="center-content"
                style={{
                  textDecoration: "underline",
                  color: "black",
                  display: "block",
                }}
              >
                read more about the solution
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
