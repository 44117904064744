import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
// import Modal from "../elements/Modal";

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  // const [videoModalActive, setVideomodalactive] = useState(false);

  // const openModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(true);
  // };

  // const closeModal = (e) => {
  //   e.preventDefault();
  //   setVideomodalactive(false);
  // };

  const outerClasses = classNames(
    "hero section center-content",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "hero-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1
              className="mt-0 mb-16 reveal-from-bottom"
              data-reveal-delay="200"
            >
              Caring is good business.{" "}
              <span className="text-color-primary">
                <strong>So make it everyone's business</strong>
              </span>
            </h1>
            <div className="container-xs">
              <p
                className="m-0 mb-32 reveal-from-bottom"
                data-reveal-delay="400"
              >
                Phetty is a free, all-inclusive gifting platform that enables
                meaningful 1-to-1 gifting to customers, partners, employees, or
                whoever deserves it. Right when it matters the most and wherever
                you need it.
              </p>
              <div className="reveal-from-bottom" data-reveal-delay="600">
                <ButtonGroup className="mb-16">
                  <Button
                    tag="a"
                    color="dark"
                    wideMobile
                    // eslint-disable-next-line no-undef
                    onClick={() => $crisp.push(["do", "chat:open"])}
                  >
                    Get early access
                  </Button>
                  <Button
                    tag="a"
                    color="primary"
                    wideMobile
                    href="https://calendly.com/phetty/demo"
                  >
                    Book a demo
                  </Button>
                </ButtonGroup>
                <a
                  href="https://app.phetty.com"
                  className="mt-16 text-xs"
                  style={{
                    display: "block",
                    color: "black",
                    textDecoration: "underline",
                  }}
                >
                  Already on Phetty? Log in
                </a>
              </div>
            </div>
          </div>
          <div
            className="hero-figure reveal-from-bottom illustration-1.png"
            data-reveal-value="20px"
            data-reveal-delay="800"
          >
            {/* <a
              data-video="https://player.vimeo.com/video/174002812"
              href="#0"
              aria-controls="video-modal"
              onClick={openModal}
            > */}
            <Image
              src={require("./../../assets/images/illustration-1.png")}
              alt="Hero"
            />
            {/* </a> */}
          </div>
          {/* <Modal
            id="video-modal"
            show={videoModalActive}
            handleClose={closeModal}
            video="https://player.vimeo.com/video/174002812"
            videoTag="iframe"
          /> */}
        </div>
      </div>
    </section>
  );
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
