import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    titleSecond: PropTypes.string,
    paragraph: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(["h1", "h2", "h3"]),
  invertedColors: PropTypes.bool,
};

const defaultProps = {
  children: null,
  tag: "h2",
};

const SectionHeader = ({
  className,
  data,
  children,
  tag,
  invertedColors,
  ...props
}) => {
  const classes = classNames("section-header", className);

  const Component = tag;

  return (
    <>
      {(data.title || data.paragraph) && (
        <div {...props} className={classes}>
          <div className="container-xs">
            {children}
            {data.title && (
              <Component
                className={classNames(
                  "mt-0",
                  data.paragraph ? "mb-16" : "mb-0",
                  invertedColors && "text-color-high"
                )}
              >
                {data.title} <strong>{data.titleSecond}</strong>
              </Component>
            )}
            {data.paragraph && (
              <p
                className={classNames(
                  "m-0",
                  invertedColors && "text-color-high"
                )}
              >
                {data.paragraph}
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
