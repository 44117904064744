import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import Accordion from "../../components/elements/Accordion";
import AccordionItem from "../../components/elements/AccordionItem";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

const BenefitQA2 = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  invertMobile,
  invertDesktop,
  alignTop,
  ...props
}) => {
  const outerClasses = classNames(
    "benefit-qa-2 section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "benefit-qa-2-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const splitClasses = classNames(
    "split-wrap",
    invertMobile && "invert-mobile",
    invertDesktop && "invert-desktop",
    alignTop && "align-top"
  );

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <div className={splitClasses}>
            <div className="split-item">
              <div
                className="split-item-content reveal-from-left"
                data-reveal-container=".split-item"
              >
                <Image
                  className={classNames("split-item-image-inner mb-8")}
                  src={require("./../../assets/images/icons/big-gifts.svg")}
                  alt="platform icon"
                  style={{
                    width: 45,
                  }}
                />
                <div className="text-xxs fw-600 tt-u mb-8">Benefits</div>
                <h3 className="benefit-title mt-0 mb-12 fw-500">The Gifts</h3>
                <p className="m-0">
                  We handpick, store, and package every single gift in-house to
                  ensure maximum quality and complete coherence with your brand.
                </p>
              </div>
              <div
                className="split-item-content reveal-from-right"
                data-reveal-container=".split-item"
              >
                <Accordion className="questions">
                  <AccordionItem
                    title="We handpick all gifts"
                    className="accordion-item"
                  >
                    Instead of each employee wasting time going to a random
                    store to select another random gift, we have done all the
                    necessary and hard work with our 1-click curated gift store.
                    We handpick every gift with an eye for outstanding quality
                    and design, and when possible, we also focus on choosing the
                    local and sustainable. Sit back and empower your entire team
                    to easily send memorable gifts in the range of $25 - $200
                    for any possible occasion without all the hassle.
                  </AccordionItem>
                  <AccordionItem title="We streamline and make it yours">
                    Delivering a consistent experience that matches your brand
                    and ensures a positive impression is crucial. We make it
                    easy to streamline your gifting program - even at scale -
                    with gifts that you pre-approve from our already highly
                    curated gift store. Select the gifts that best match your
                    brand, and we will - for free - package them responsibly and
                    on-brand together with personal handwritten cards.
                  </AccordionItem>
                  <AccordionItem title="We do all the leg work">
                    You should focus on running your business. You should not
                    waste valuable resources launching and managing a gifting
                    program that - apart from being costly - involves a ton of
                    administrative headaches and heavy lifting for you and your
                    team. We do all the leg work so that you can keep full
                    control and oversight like never before and your team can
                    send memorable gifts in just a few clicks.
                  </AccordionItem>
                  <AccordionItem title="We handle messaging as well">
                    The message that you send with a gift makes a huge
                    difference. It shows that you care. So you need to make it
                    count. While you focus on writing the right message, we
                    ensure that we deliver it the best way possible with
                    handpicked gift cards that we handwrite when needed.
                  </AccordionItem>
                  <AccordionItem title="We package responsibly">
                    Of internet users worldwide, 47% have ditched products and
                    services from a brand that violated their values. As
                    protecting the environment topped that list, our first
                    choice for packaging is always recycled or biodegradable
                    protective packaging.
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BenefitQA2.propTypes = propTypes;
BenefitQA2.defaultProps = defaultProps;
export default BenefitQA2;
