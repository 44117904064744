import React from "react";
// import sections
import Cta from "../components/sections/Cta";
import HeroSolutions from "../components/sections/Hero-Solutions";
import BenefitQA1 from "../components/sections/BenefitQA-1";
import BenefitQA2 from "../components/sections/BenefitQA-2";
import BenefitQA3 from "../components/sections/BenefitQA-3";
import SolutionTiles from "../components/sections/SolutionTiles";

const Solution = () => {
  return (
    <>
      <HeroSolutions />
      <BenefitQA1 />
      <BenefitQA2 />
      <BenefitQA3 />
      <SolutionTiles />
      <Cta />
    </>
  );
};

export default Solution;
