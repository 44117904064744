import React from "react";
import classNames from "classnames";
import SectionHeader from "./partials/SectionHeader";
import SmoothScroll from "../elements/SmoothScroll";

const FeaturesBasic = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    "features-basic section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-basic-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Why gifting?",
    titleSecond: "Because done right, it pays off.",
    paragraph:
      "Care in the form of authentic appreciation and recognition leads to gratitude, which in turn leads to loyalty - this amongst everyone important to your business. For us, gifting is about elevating care beyond a simple “thanks” and putting people back where they belong - at the heart of your business. ",
  };

  return (
    <section {...props} className={outerClasses} id="why-gifting">
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content section-header"
          />
          <SmoothScroll
            to="why-phetty"
            className="center-content"
            style={{
              display: "block",
              textDecoration: "underline",
            }}
          >
            Why Phetty?
          </SmoothScroll>
        </div>
      </div>
    </section>
  );
};

export default FeaturesBasic;
